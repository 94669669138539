<template>
  <div>
    <!-- begin:: "Go Back" button -->
    <v-row>
      <v-col>
        <v-btn text class="mb-2" @click="goBack">
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          Regresar
        </v-btn>
      </v-col>
    </v-row>

    <!-- end:: "Go Back" button -->
    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin:: Page main title -->
        <v-row>
          <v-col cols="12" class="pa-0">
            <p
              class="display-1 font-weight-medium text-center text-md-left text-blue-grey lighten-2 mb-1 mt-3"
            >
              Ficha de estudiante
            </p>
          </v-col>
        </v-row>
        <!-- end:: Page main title -->

        <!-- begin::main container for all report cards-->

        <v-row class="mt-8 mb-3 d-flex justify-center justify-md-start">
          <!-- begin:: main card container -->
          <v-col cols="12" sm="9" md="7" lg="5" class=" mt-md-0 pl-md-5">
            <v-row class="elevation-2 rounded-lg pa-2 pa-sm-5">
              <!-- begin ::score sheet  -->
              <v-row>
                <v-col class="pa-sm-0">
                  <v-card
                    class="mx-auto grey lighten-4 rounded score-sheet-card-large "
                    outlined
                  >
                    <v-list-item three-line>
                      <!-- begin::card title -->
                      <v-list-item-content>
                        <div class="text-overline mb-4">
                          Estudiantes
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                          Ficha de estudiante
                        </v-list-item-title>
                        <p class="text-body-2 ma-0">
                          La ficha resumen contiene información del estudiante
                          obtenida en la ultima acualización de datos.
                        </p>
                      </v-list-item-content>
                      <!-- end::card title -->
                      <!-- begin::card icon -->
                      <v-list-item-avatar
                        class="rounded red-pdf"
                        tile
                        icon
                        size="80"
                      >
                        <v-icon x-large color="white" class="ml-n1"
                          >mdi-file-pdf-box</v-icon
                        ></v-list-item-avatar
                      >
                      <!-- end::card icon -->
                    </v-list-item>
                    <!-- begin:: generate score sheet btn -->
                    <v-card-actions>
                      <v-btn
                        outlined
                        rounded
                        text
                        :href="generateStudentFile"
                        download
                        target="_blank"
                      >
                        Generar ficha
                      </v-btn>
                    </v-card-actions>
                    <!-- end:: generate score sheet btn -->
                  </v-card>
                  <!-- begin::fallback card when data is loading or the user hasn't got permision to generate -->
                  <v-card
                    class="mx-auto grey lighten-4 rounded score-sheet-card-small"
                    outlined
                  >
                    <p class="text-overline text-center ma-0">
                      Estudiantes
                    </p>
                    <p class="text-h5 text-center">Ficha de estudiante</p>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-list-item-avatar
                          class="rounded red-pdf ma-0"
                          tile
                          icon
                          size="80"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-file-pdf-box</v-icon
                          ></v-list-item-avatar
                        >
                      </v-col>
                    </v-row>
                    <v-row class="mb-2">
                      <v-col class="d-flex justify-center">
                        <v-btn
                          outlined
                          rounded
                          text
                          :href="generateStudentFile"
                          download
                          target="_blank"
                        >
                          Generar ficha
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                  <!-- end::fallback card when data is loading or the user hasn't got permision to generate -->
                </v-col>
              </v-row>
              <!-- end ::score sheet -->
            </v-row>
          </v-col>
          <!-- begin:: main card container -->
        </v-row>
        <!-- end::main container for all report cards -->
      </template>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
export default {
  name: "studentFile",
  title: "Ficha de estudiante | PE ITR",
  mounted() {
    this.encriptId();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Información personal", route: "/profile/student_profile" },
      { title: "Descargas", route: "/profile/downloads" },
      { title: "Ficha de estudiante", route: "student_file" },
    ]);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    //Access de data from the "reportsge.ricaldone.edu.sv" url to generate up to date score sheets
    generateStudentFile() {
      let baseURL;
      if (process.env.REPORTS_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.REPORTS_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return `${baseURL}/reportes/public/form/${sessionStorage.getItem(
        "studentUUID"
      )}`;
    },
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>

<style scoped>
.red-pdf {
  background-color: #d93025 !important;
}

.score-sheet-card-small {
  display: none;
}

@media (max-width: 450px) {
  .score-sheet-card-large {
    display: none;
  }
  .score-sheet-card-small {
    display: block;
  }
}
</style>
